<template>
  <b-row>
    <b-col>
      <div class="d-flex justify-content-between ml-1">
        <h2>{{ $t('dashboard.collaborations') }}</h2>

        <div v-if="active_tab === 1">
          <b-button
            variant="blue-button" 
            class="blue-button" 
            @click="user.current_plan.create_casting_call ? $router.push({ name: 'steps_casting_call', params: {step: 'select-service'}}) : open_modal_plans_group = true; update_modal = !update_modal"
          >{{ $t("dashboard.create_casting_call")}}</b-button>
        </div>
      </div>
      <b-tabs content-class="mt-3"  v-model="active_tab">
        <b-tab title-link-class="custom-tab" :title="$t('creator.desk')" @click="$router.push({name: 'dashboard-analytics'})"></b-tab>
        <b-tab 
          title-link-class="custom-tab" 
          :title="$t('dashboard.campaigns')" 
          @click="$router.push({ name: 'my_work_brands', params: { section: 'campaigns' } }).catch(() => {})"
          :active="$route.params.section === 'campaigns'"
        >
          <casting-calls-table :summary="summary"></casting-calls-table>
        </b-tab>
        <b-tab 
          title-link-class="custom-tab" 
          :title="$t('creator.myOrders')" 
          @click="$router.push({ name: 'my_work_brands', params: { section: 'orders' } }).catch(() => {})"
          :active="$route.params.section === 'orders'"
        >
          <orders-brand :summary="summary" :key="update_orders"></orders-brand>
        </b-tab>
      </b-tabs>
    </b-col>
    <modal-membership-group
      :open_modal_plans_group="open_modal_plans_group" 
      :key="update_modal"
      :texts="{title: $t('creator.msjTitlePremium+Brand'), subtitle: $t('creator.subtitlePremium+Brand')}"
    />
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BButton,
} from 'bootstrap-vue';
import { getUserData } from '@/libs/utils/user';
import casting_calls_services from '@/services/casting_calls';

export default {
  name: 'myWorkBrands',
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
    castingCallsTable: () => import('@/views/pages/casting-calls/my-work/castingCallsTable.vue'),
    ordersBrand: () => import('@/views/pages/casting-calls/my-work/ordersBrand.vue'),
    ModalMembershipGroup: () => import('@/views/components/modal/ModalMembershipGroup.vue'),
  },
  data() {
    return {
      active_tab: 1,
      user: getUserData(),
      summary: {},
      update_orders: false,
      open_modal_plans_group: false,
      update_modal: false,
    }
  },
  created() {
    this.getSummaryWork();
  },
  methods: {
    getSummaryWork() {
      casting_calls_services.getSummaryWork().then((response) => {
        this.summary = response;
        this.update_orders = !this.update_orders;
      })
    },
  }
}
</script>
<style scoped>
.blue-button-plan,
.blue-button-plan:focus {
background-color: #0096fe;
border: 0;
color: white;
width: 100%;
}
.blue-button-plan:hover {
background-color: rgb(77, 182, 249);
box-shadow: rgba(77, 182, 249, 0.3) 0px 7px 29px 0px;
color: white;
width: 100%;
}
</style>
<style>
.custom-tab:not(.active) {
  color: #8d8d9d8f;
}
</style>
